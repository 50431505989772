import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/header"; // Assurez-vous que le chemin vers votre composant Header est correct
import Accueil from "./components/barberaccueil";
import Footer from "./components/footer";
import Login from "./components/login";
// import RDV from "./components/rdv";
import Calendar from "./components/calendar";
import Mesrendezvous from "./components/MesRendezvous";
import ErrorRDV from "./components/Error";

function App() {
  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <div className="flex-1">
        <Routes>
          {/* Route pour la page d'accueil */}
          <Route path="/" element={<Accueil />} />
          {/* Route pour la page Login */}
          <Route path="/login" element={<Login />} />

          {/* <Route path="/rdv" element={<RDV />} /> */}

          <Route path="/rdv" element={<ErrorRDV />} />

          <Route path="/calendrier" element={<Calendar />} />

          <Route path="/MesRdv" element={<Mesrendezvous />} />

          {/* Route par défaut pour les erreurs
        <Route path="*" element={<Error />} /> */}
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
