import React from "react";

function Prestation() {
  return (
    <div className="lg:w-[90rem] md:w-[39rem] w-[22rem] md:h-[100%] lg:h-[100%] mx-auto mt-4 lg:pl-[5%] md:pl-[5%]">
      <h2 className="text-3xl font-bold mb-8 text-center">
        Découvrez Mes Prestations
      </h2>
      <div className="flex flex-col md:flex-row flex-wrap gap-4 mb-[2rem]">
        <div className="bg-white w-full md:w-[45%] mb-4 rounded-lg shadow-md overflow-hidden">
          <div className="p-4">
            <h3 className="text-lg lg:text-[25px] text-black font-semibold mb-2 md:mb-4">
              Coupe Entretien
            </h3>
            <p className="text-gray-700 leading-snug text-[15px] lg:text-[21px]">
              Optez pour une coupe entretien si vous avez coupé vos cheveux il y
              a moins de 2 mois.
            </p>
          </div>
        </div>
        <div className="bg-white w-full md:w-[45%] mb-4 rounded-lg shadow-md overflow-hidden">
          <div className="p-4">
            <h3 className="text-lg lg:text-[25px] text-black font-semibold mb-2 md:mb-4">
              Coupe transformation
            </h3>
            <p className="text-gray-700 leading-snug text-[15px] lg:text-[21px]">
              Transformer votre coupe si vous n'avez pas coupé vos cheveux
              depuis 2 mois ou plus.
            </p>
          </div>
        </div>
        <div className="bg-white w-full md:w-[45%] mb-4 rounded-lg shadow-md overflow-hidden">
          <div className="p-4">
            <h3 className="text-lg lg:text-[25px] text-black font-semibold mb-2 md:mb-4">
              Coupe + Barbe entretien
            </h3>
            <p className="text-gray-700 leading-snug text-[15px] lg:text-[21px]">
              Optez pour une coupe + Barbe entretien si vous les avez coupé il y
              a moins de 2 mois.
            </p>
          </div>
        </div>
        <div className="bg-white w-full md:w-[45%] mb-4 rounded-lg shadow-md overflow-hidden">
          <div className="p-4">
            <h3 className="text-lg lg:text-[25px] text-black font-semibold mb-2 md:mb-4">
              Coupe + Barbe transformation
            </h3>
            <p className="text-gray-700 leading-snug text-[15px] lg:text-[21px]">
              Transformer votre coupe et votre barbe si vous ne les avez pas
              coupé depuis 2 mois ou plus.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prestation;
