import React from "react";

const ErrorRDV = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="text-center bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-2xl font-semibold text-gray-800 mb-4">
          Rendez-vous indisponibles
        </h1>
        <p className="text-lg text-gray-600">
          Les rendez-vous ne sont pas disponibles pour l'instant.
        </p>
        <p className="text-lg text-gray-600">Merci de revenir prochainement.</p>
        <a href="/" className="text-blue-500 hover:text-blue-700 underline">
          Retour à l'accueil
        </a>
      </div>
    </div>
  );
};

export default ErrorRDV;
