import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScissors, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate

  const token = Cookies.get("access-token");

  const getWindowDimensions = () => {
    const { innerWidth: width } = window;
    return width;
  };

  useEffect(() => {
    const handleResize = () => {
      if (getWindowDimensions() > 821) {
        setShowMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const validateToken = async (token) => {
    try {
      const response = await fetch(
        "https://api-barber-one.vercel.app/validate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ token }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setIsLoggedIn(data.valid);
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  const getUser = async (token) => {
    try {
      const response = await fetch("https://api-barber-one.vercel.app/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      if (response.ok) {
        if (data.data.isAdmin === 0) {
          setIsAdmin(false);
        } else {
          setIsAdmin(true);
        }
      }
    } catch (error) {
      console.log(error);
      console.error("Erreur lors de la connexion:", error);
    }
  };

  if (token !== "" && token !== undefined && isLoggedIn === false) {
    validateToken(token);
  }
  if (isLoggedIn === true) {
    getUser(token);
  }

  const handleLogout = async () => {
    try {
      const response = await fetch("https://api-barber-one.vercel.app/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ token }),
      });
      if (response.ok) {
        setIsLoggedIn(false);
        document.cookie =
          "access-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate("/"); // Use navigate to redirect to the home page
        document.location.reload();
      } else {
        console.error("Erreur lors de la déconnexion:", response.statusText);
      }
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
    }
  };

  return (
    <header className="bg-black flex justify-between items-center sticky top-0 z-50 h-[9rem] lg:h-[6rem] px-4 lg:px-8">
      <div className="flex items-center">
        <FontAwesomeIcon
          className="text-white mr-4 lg:mr-0 lg:ml-4 ml-5px"
          icon={faScissors}
          size="2x"
        />
        <a
          href="/"
          className="text-white  font-bold text-lg lg:text-xl hover:text-gray-300"
        >
          TM Barber - Violaine
        </a>
      </div>

      <nav className={`lg:flex lg:mt-0 ${showMenu ? "block" : "hidden"}`}>
        <ul className="flex justify-end  md:flex lg:flex-row flex-wrap lg:items-center gap-2 lg:gap-6">
          <li
            className={`flex-shrink-0 ${
              isLoggedIn ? "hidden lg:flex" : "lg:flex"
            }`}
          >
            <a
              href="/#presta"
              className="justify-center flex w-[9rem] lg:w-[14rem] text-white font-bold bg-gray-600 hover:bg-gray-800 py-[4px] px-4 lg:px-6 text-sm lg:text-base hover:text-gray-300"
            >
              Mes Préstations
            </a>
          </li>
          <li
            className={`flex-shrink-0 ${
              isLoggedIn ? "hidden lg:flex" : "lg:flex"
            }`}
          >
            <a
              href="/#produits"
              className="justify-center flex w-[9rem] lg:w-[14rem] text-white font-bold bg-gray-600 hover:bg-gray-800 py-[4px] px-4 lg:px-6 text-sm lg:text-base hover:text-gray-300"
            >
              Mes Produits
            </a>
          </li>

          {isLoggedIn && !isAdmin && (
            <li className="flex-shrink-0">
              <Link
                to="/MesRdv"
                className="justify-center flex w-[9rem] lg:w-[14rem] text-white font-bold bg-gray-600 hover:bg-gray-800 py-[4px] px-4 lg:px-6 text-sm lg:text-base hover:text-gray-300"
              >
                Mes rendez-vous
              </Link>
            </li>
          )}

          {isLoggedIn && isAdmin && (
            <li className="flex-shrink-0">
              <Link
                to="/calendrier"
                className="justify-center flex w-[9rem] lg:w-[14rem] text-white font-bold bg-gray-600 hover:bg-gray-800 py-[4px] px-4 lg:px-6 text-sm lg:text-base hover:text-gray-300"
              >
                Calendrier
              </Link>
            </li>
          )}
          {isLoggedIn && (
            <li className="flex-shrink-0">
              <button
                className="justify-center flex w-[9rem] lg:w-[14rem] text-white font-bold bg-red-600 hover:bg-red-800 py-[4px] px-4 lg:px-6 text-sm lg:text-base hover:text-gray-300"
                onClick={handleLogout}
              >
                Déconnexion
              </button>
            </li>
          )}
          <li className="flex-shrink-0">
            <Link
              to="/login"
              className="justify-center flex w-[9rem] lg:w-[12rem] text-white font-bold bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-600 py-[4px] px-4 lg:px-6 text-sm lg:text-base hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300"
            >
              {!isAdmin ? "Rendez-vous" : "Prendre Congés"}
            </Link>
          </li>
        </ul>
      </nav>

      {/* Bouton du menu hamburger */}
      <div className="lg:hidden">
        <button type="button" onClick={() => setShowMenu(!showMenu)}>
          <FontAwesomeIcon
            icon={showMenu ? faTimes : faBars}
            className="text-white ml-[15px]"
            size="2x"
          />
        </button>
      </div>
    </header>
  );
}

export default Header;
