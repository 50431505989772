import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const AppointmentForm = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedHour, setSelectedHour] = useState("");
  let [selectedPresta, setSelectedPresta] = useState("");
  const [decodeToken, setDecodeToken] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Etat pour le chargement
  const navigate = useNavigate();
  const token = Cookies.get("access-token");
  let [RdvHeure, setRdvHeure] = useState([
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
  ]);

  let Congés = [
    "Toute la matinée",
    "Toute l'après-midi",
    "Toute la journée",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
  ];

  const getUser = async (token) => {
    try {
      const response = await fetch("https://api-barber-one.vercel.app/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      if (response.ok) {
        setDecodeToken(data.data);
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  const majHoraire = async (dateP) => {
    console.log("maj horaire appelé");
    try {
      const response = await fetch(
        "https://api-barber-one.vercel.app/appointments/date/reservable",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            date: dateP,
          }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        setRdvHeure(data);
        setSelectedDate(dateP);
      }
    } catch (error) {
      console.error("Erreur lors de la requête API:", error);
    }
  };

  const validateToken = async (token) => {
    try {
      const response = await fetch(
        "https://api-barber-one.vercel.app/validate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ token }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        if (!data.valid) {
          navigate("/");
        } else {
          if (decodeToken === "" || decodeToken === undefined) {
            getUser(token);
          }
        }
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  if (decodeToken === "" || decodeToken === undefined) {
    validateToken(token);
  }

  const isWeekend = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDay(); // 0 (dimanche) à 6 (samedi)
    return day === 0 || day === 6; // Dimanche (0) ou samedi (1)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let emplacement = document.getElementById("messageBox");
    emplacement.textContent = "";
    setIsLoading(true); // Activer le chargement

    let email = decodeToken.email;
    let name = decodeToken.name;
    let firstname = decodeToken.firstname;
    let number = decodeToken.number;

    const maintenant = new Date();
    const year = maintenant.getFullYear();
    const month = (maintenant.getMonth() + 1).toString().padStart(2, "0");
    const day = maintenant.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    const formattedSelectedDate = new Date(selectedDate);
    const formattedSelectedDateString = `${formattedSelectedDate.getFullYear()}-${(
      formattedSelectedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${formattedSelectedDate
      .getDate()
      .toString()
      .padStart(2, "0")}`;

    if (decodeToken.isAdmin) {
      selectedPresta = "Congés";
    }

    if (
      formattedSelectedDateString >= formattedDate &&
      !isWeekend(selectedDate)
    ) {
      try {
        const response = await fetch(
          "https://api-barber-one.vercel.app/appointments",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              token: token,
              date: selectedDate,
              heure: selectedHour,
              typePrestation: selectedPresta,
              email: email,
              nom: name,
              prenom: firstname,
              number: number,
            }),
          }
        );

        if (response.ok) {
          let successMessage = "Rendez-vous pris avec succès";
          if (selectedPresta === "Congés") {
            successMessage = "Congés pris avec succès";
          }
          let emplacement = document.getElementById("messageBox");
          emplacement.textContent = "";
          const paragraph = document.createElement("p");
          paragraph.textContent = successMessage;
          paragraph.setAttribute("style", "color: green");
          emplacement.appendChild(paragraph);
          setTimeout(() => {
            navigate("/MesRdv");
          }, 1000);
        } else {
          let emplacement = document.getElementById("messageBox");
          emplacement.textContent = "";
          const paragraph = document.createElement("p");
          paragraph.textContent = "Le créneau choisi n'est pas réservable";
          paragraph.setAttribute("style", "color: red");
          emplacement.appendChild(paragraph);
        }
      } catch (error) {
        let emplacement = document.getElementById("messageBox");
        emplacement.textContent = "";
        const paragraph = document.createElement("p");
        paragraph.textContent = "Rendez-vous impossible à réserver";
        paragraph.setAttribute("style", "color: red");
        emplacement.appendChild(paragraph);
        console.error("Erreur lors de la requête API:", error);
      } finally {
        setIsLoading(false); // Désactiver le chargement après l'achèvement
      }
    } else {
      let emplacement = document.getElementById("messageBox");
      emplacement.textContent = "";
      const paragraph = document.createElement("p");
      if (isWeekend(selectedDate)) {
        paragraph.textContent =
          "Les rendez-vous ne sont pas disponibles les samedi et les dimanche";
      } else {
        paragraph.textContent = "Date choisie dépassée";
      }
      paragraph.setAttribute("style", "color: red");
      emplacement.appendChild(paragraph);
      setIsLoading(false); // Désactiver le chargement si la date est dépassée
    }
  };

  const [defaultPresta] = useState([
    "Coupe Entretien",
    "Coupe transformation (+ 2 mois de pousse)",
    "Coupe + Barbe entretien",
    "Coupe + Barbe transformation (+ 2 mois de pousse)",
  ]);

  const weekendClass = isWeekend(selectedDate) ? "weekend" : "";

  return (
    <div className="lg:mt-[-18rem] lg:pt-[13rem] lg:pl-[6rem] min-h-screen bg-gray-100 flex justify-center items-center">
      <div className="lg:mt-[6pc]  max-w-md mx-auto bg-white p-8 rounded shadow-md">
        <span className="text-2xl font-bold">
          {!decodeToken.isAdmin ? "Prendre Rendez-vous" : "Prendre Congés"}
        </span>

        {isLoading && (
          <div className="flex justify-center mt-4">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        )}

        <div id="messageBox"></div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="date">
              <span className="block mt-[1rem] font-medium ">
                {!decodeToken.isAdmin
                  ? "date du Rendez-vous"
                  : "date du Congés"}
              </span>
            </label>
            <input
              type="date"
              id="date"
              value={selectedDate}
              onChange={(e) => {
                majHoraire(e.target.value);
                setSelectedDate(e.target.value);
              }}
              className={`w-full rounded border-gray-300 border p-2 focus:outline-none focus:border-gray-500 ${weekendClass}`}
              min={new Date().toISOString().split("T")[0]} // La date minimale est aujourd'hui
              required
            />
            {isWeekend(selectedDate) && (
              <p className="text-red-500">
                Indisponible les Samedi et les dimanche
              </p>
            )}
          </div>

          <div>
            <label htmlFor="heures">
              <span className="block mt-[1rem] font-medium ">
                {!decodeToken.isAdmin
                  ? "heure du Rendez-vous"
                  : "heure du repos"}
              </span>
            </label>
            <select
              id="heure"
              value={selectedHour}
              onChange={(e) => setSelectedHour(e.target.value)}
              className="w-full rounded border-gray-300 border p-2 focus:outline-none focus:border-gray-500"
              required
            >
              <option value="">Choisir une heure</option>
              {decodeToken.isAdmin
                ? Congés.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))
                : RdvHeure.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
            </select>
          </div>

          <div>
            <label htmlFor="presta" className="block font-medium">
              <span className="block mt-[1rem] font-medium ">
                {!decodeToken.isAdmin ? "prestation" : ""}
              </span>
            </label>
            {!decodeToken.isAdmin ? (
              <select
                id="presta"
                value={selectedPresta}
                onChange={(e) => setSelectedPresta(e.target.value)}
                className="w-full rounded border-gray-300 border p-2 focus:outline-none focus:border-gray-500"
                required
              >
                <option value="">Choisir une prestation</option>
                {defaultPresta.map((typePrestation) => (
                  <option key={typePrestation} value={typePrestation}>
                    {typePrestation}
                  </option>
                ))}
              </select>
            ) : (
              <div></div>
            )}
          </div>

          <div>
            <label htmlFor="email" className="block font-medium">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={decodeToken.email}
              disabled
              className="w-full rounded border-gray-300 border p-2 focus:outline-none focus:border-gray-500"
              required
            />
          </div>
          <div>
            <label htmlFor="name" className="block font-medium">
              Nom
            </label>
            <input
              type="text"
              id="name"
              value={decodeToken.name}
              disabled
              className="w-full rounded border-gray-300 border p-2 focus:outline-none focus:border-gray-500"
              required
            />
          </div>
          <div>
            <label htmlFor="firstname" className="block font-medium">
              Prénom
            </label>
            <input
              type="text"
              id="firstname"
              value={decodeToken.firstname}
              disabled
              className="w-full rounded border-gray-300 border p-2 focus:outline-none focus:border-gray-500"
              required
            />
          </div>

          <div>
            <label htmlFor="number" className="hidden block font-medium">
              Numéro de téléphone
            </label>
            <input
              type="text"
              id="number"
              value={decodeToken.number}
              disabled
              hidden
              className="w-full rounded border-gray-300 border p-2 focus:outline-none focus:border-gray-500"
              required
            />
          </div>

          {!decodeToken.isAdmin ? (
            <div
              className="bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md"
              role="alert"
            >
              <p className="font-bold">Information :</p>
              <p>Le salon se situe au 49 rue des chênes, Violaine 62138</p>
            </div>
          ) : (
            ""
          )}

          <button
            type="submit"
            className="w-full bg-black text-white rounded py-2 font-bold hover:bg-gray-900 transition duration-300"
          >
            Réserver
          </button>
        </form>

        <div className="mt-4 text-center">
          <a href="/" className="text-blue-500 hover:text-blue-700 underline">
            Retour à l'accueil
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppointmentForm;
