import React, { useState, useEffect } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/fr"; // Importer les paramètres régionaux français
import "react-big-calendar/lib/css/react-big-calendar.css";
import SelectedAppointments from "./SelectedAppointments";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";


moment.locale("fr"); // Définir la langue de moment en français

const localizer = momentLocalizer(moment);

const Calendar = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const token = Cookies.get("access-token");
  const [decodeToken, setDecodeToken] = useState("");

  const getUser = async (token) => {
    try {
      const response = await fetch("https://api-barber-one.vercel.app/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      if (response.ok) {
        setDecodeToken(data.data);
        if (!data.data.isAdmin) {
          navigate("/");
        }
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  const validateToken = async (token) => {
    try {
      const response = await fetch(
        "https://api-barber-one.vercel.app/validate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ token }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        if (!data.valid) {
          navigate("/");
        } else {
          if (decodeToken === "" || decodeToken === undefined) {
            getUser(token);
          }
        }
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  if (decodeToken === "" || decodeToken === undefined) {
    validateToken(token);
  }

  useEffect(() => {
    // Effectuer une requête API pour récupérer tous les rendez-vous
    fetch("https://api-barber-one.vercel.app/appointments")
      .then((response) => response.json())
      .then((data) => {
        // Transformer les données des rendez-vous dans le format attendu par react-big-calendar
        const formattedEvents = data.map((appointment) => ({
          title: `${appointment.nom} ${appointment.prenom}`,
          start: new Date(appointment.date + "T" + appointment.heure),
          end: new Date(appointment.date + "T" + appointment.heure),
          number: appointment.number,
        }));
        setEvents(formattedEvents);

        // Filtrer les rendez-vous pour la date sélectionnée
        const appointmentsForSelectedDate = data.filter((appointment) =>
          moment(appointment.date).isSame(selectedDate, "day")
        );
        setSelectedAppointments(appointmentsForSelectedDate);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des rendez-vous:", error);
      });
  }, [selectedDate]);

  const handleSelectDate = (date) => {
    setSelectedDate(date);
  };

  const hasAppointments = (date) => {
    // Vérifier si la date a des rendez-vous
    return events.some((event) => moment(event.start).isSame(date, "day"));
  };

  const customDayPropGetter = (date) => {
    const dayClasses = [];

    // Ajouter une classe pour la date sélectionnée
    if (moment(date).isSame(selectedDate, "day")) {
      dayClasses.push("selected-day");
    }

    // Ajouter une classe pour les dates avec des rendez-vous
    if (hasAppointments(date)) {
      dayClasses.push("has-appointments");
    }

    return { className: dayClasses.join(" ") };
  };

  // Messages personnalisés en français pour les boutons de navigation
  const customMessages = {
    next: "Suivant",
    previous: "Précédent",
    today: "Aujourd'hui",
    month: "Mois",
    week: "Semaine",
    day: "Jour",
    agenda: "Agenda",
    date: "Date",
    time: "Heure",
    event: "Événement",
    allDay: "Toute la journée",
    showMore: (total) => `+${total} de plus`,
  };

  return (
    <div className="max-w-lg mx-auto bg-white p-4 shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Calendrier des rendez-vous</h2>
      <div className="bg-gray-100 rounded-lg p-4 mb-4">
        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          onSelectSlot={(slotInfo) => handleSelectDate(slotInfo.start)}
          selectable
          defaultView="month"
          views={["month"]}
          className="rounded-lg border border-gray-200"
          style={{ height: "500px" }}
          dayPropGetter={customDayPropGetter}
          messages={customMessages} // Utiliser des messages personnalisés en français
        />
      </div>
      {/* Afficher les rendez-vous du jour sélectionné */}
      <SelectedAppointments
        selectedDate={selectedDate}
        selectedAppointments={selectedAppointments}
      />
    </div>
  );
};

export default Calendar;
