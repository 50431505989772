import React, { useState } from "react";
import axios from "axios"; // Importez axios pour effectuer des appels HTTP
import Cookies from "js-cookie";

function Produit() {
  const [isAdmin, setIsAdmin] = useState(false);

  // Tableau de produits
  const [produits, setProduits] = useState([]);

  // Diviser les produits en sous-tableaux de 4 éléments chacun
  const produitsParLigne = [];
  for (let i = 0; i < produits.length; i += 4) {
    produitsParLigne.push(produits.slice(i, i + 4));
  }

  const getAllProduits = async () => {
    try {
      const response = await fetch(
        "https://api-barber-one.vercel.app/allProduits",
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const data = await response.json();

      if (response.ok) {
        setProduits(data);
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  if (produits.length === 0) {
    getAllProduits();
  }

  const token = Cookies.get("access-token");
  const [decodeToken, setDecodeToken] = useState("");

  const getUser = async (token) => {
    try {
      const response = await fetch("https://api-barber-one.vercel.app/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      if (response.ok) {
        setDecodeToken(data.data);
        if (data.data.isAdmin) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  const validateToken = async (token) => {
    try {
      const response = await fetch(
        "https://api-barber-one.vercel.app/validate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ token }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        if (data.valid) {
          if (decodeToken === "" || decodeToken === undefined) {
            getUser(token);
          }
        }
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  if (decodeToken === "" || decodeToken === undefined) {
    validateToken(token);
  }

  return (
    <div>
      {produitsParLigne.map((ligne, index) => (
        <div
          key={index}
          className="flex justify-center gap-4 flex-wrap md:flex-nowrap"
        >
          {ligne.map((produit, index) => (
            <div
              key={index}
              className={
                produit.isForm && !isAdmin
                  ? "hidden"
                  : "w-full mb-4 max-w-sm md:w-auto md:max-w-none bg-white rounded-lg shadow-lg "
              }
            >
              {!produit.isForm ? (
                // Sinon, affichez l'image du produit
                <>
                  <img
                    className="w-[21rem] mx-auto  h-[20rem] object-cover rounded-t-lg"
                    src={
                      "https://api-barber-one.vercel.app/showimage/" +
                      produit.image
                    }
                    alt={produit.nom}
                  />

                  <div className="p-5">
                    <div className="text-lg font-semibold text-gray-900 dark:text-black ">
                      {produit.nom}
                    </div>
                  </div>
                </>
              ) : (
                <div className="hidden"></div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Produit;
