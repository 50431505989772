import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import fetch from "isomorphic-fetch";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Rdv from "./rdv.jsx";

const Login = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [password, setPassword] = useState("");
  const [number, setNumber] = useState("");
  const [isRegistering, setIsRegistering] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // Ajout du state pour le message de succès
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("access-token");

    const validateToken = async (token) => {
      try {
        const response = await fetch(
          "https://api-barber-one.vercel.app/validate",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ token }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          if (data.valid) {
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false);
          }
        }
      } catch (error) {
        console.error("Erreur lors de la connexion:", error);
      }
    };

    validateToken(token);
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (isRegistering) {
      await handleRegister();
    } else {
      await handleLogin();
    }
  };

  const handleRegister = async () => {
    try {
      const response = await fetch(
        "https://api-barber-one.vercel.app/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ email, name, firstname, password, number }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSuccessMessage("Inscription réussie !"); // Définition du message de succès
        setIsRegistering(false); // Redirige l'utilisateur vers le formulaire de connexion
      } else {
        setSuccessMessage(""); // Réinitialisation du message de succès
      }
    } catch (error) {
      console.error("Erreur lors de l'inscription:", error);
    }
  };

  const handleLogin = async () => {
    try {
      const response = await fetch("https://api-barber-one.vercel.app/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();

      if (response.ok) {
        setIsLoggedIn(true);
        const d = new Date();
        d.setTime(d.getTime() + 3 * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = "access-token" + "=" + data.token + ";" + expires;
        navigate("/rdv");
        window.location.reload(false);
      } else {
        setErrorMessage("Identifiant ou mot de passe incorrect.");
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  if (isLoggedIn) {
    return <Rdv />;
  }

  return (
    <div className="mt-[-10rem] min-h-screen bg-black flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-4">
          {isRegistering ? "Inscription" : "Connexion"}
        </h2>
        {successMessage && ( // Affichage du message de succès
          <p className="text-green-500 mb-4">{successMessage}</p>
        )}
        <form onSubmit={handleFormSubmit} className="space-y-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full rounded border-gray-300 border p-2 focus:outline-none focus:border-gray-500"
            required
          />
          {isRegistering && (
            <>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nom"
                className="w-full rounded border-gray-300 border p-2 focus:outline-none focus:border-gray-500"
                required
              />
              <input
                type="text"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                placeholder="Prénom"
                className="w-full rounded border-gray-300 border p-2 focus:outline-none focus:border-gray-500"
                required
              />
              <input
                type="text"
                value={number}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setNumber(e.target.value);
                  }
                }}
                placeholder="Numéro de Téléphone"
                className="w-full rounded border-gray-300 border p-2 focus:outline-none focus:border-gray-500"
                required
              />
            </>
          )}
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Mot de passe"
              className="w-full rounded border-gray-300 border p-2 focus:outline-none focus:border-gray-500"
              required
            />
            <button
              type="button"
              className="absolute top-1/2 right-3 transform -translate-y-1/2"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </button>
          </div>
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <button
            type="submit"
            className="w-full bg-black text-white rounded py-2 font-bold hover:bg-gray-900 transition duration-300"
          >
            {isRegistering ? "S'inscrire" : "Se connecter"}
          </button>
          <p className="text-center text-gray-600">
            {isRegistering
              ? "Vous avez déjà un compte ?"
              : "Vous n'avez pas de compte ?"}
            <button
              type="button"
              className="text-blue-500 hover:underline"
              onClick={() => setIsRegistering(!isRegistering)}
            >
              {isRegistering ? " Connectez-vous ici" : " Inscrivez-vous ici"}
            </button>
          </p>
        </form>
        <a
          href="/"
          className="block text-center mt-4 text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 inline-block mr-2 -mt-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
            />
          </svg>
          Retour à l'accueil
        </a>
      </div>
    </div>
  );
};

export default Login;
