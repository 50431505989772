import React from "react";
import Slider from "./Slider";
import Produit from "./produit";
import Prestation from "./prestation";
import map from "../assets/localisation.webp";

function Accueil() {
  return (
    <main className="relative overflow-x-hidden">
      <section className="relative text-center">
        <div className="lg:mt-[6px] md:mb-[0] sm:mb-0 flex justify-center">
          {" "}
          <div id="presta" className="max-w-[800px] w-full sm:mt-[7px]">
            {" "}
            <Slider />
          </div>
        </div>
      </section>

      <section className="bg-black text-white py-8">
        <div className="max-w-[800px] mx-auto text-center">
          <h3 className="text-3xl font-bold mb-4">Mes Horaires</h3>
          <div className="bg-white rounded-md p-4 lg:w-[40rem] mx-auto">
            <ul className="md:w-full lg:w-full xl:w-full 2xl:w-full flex flex-col gap-4">
              <li className="pb-[3%] flex items-center justify-between border-b border-gray-300">
                <span className="font-bold text-black">Lundi</span>
                <p className="text-black text-sm sm:text-base">
                  10h - 12h30 , 14h - 19h{" "}
                </p>
              </li>
              <li className="pb-[3%] flex items-center justify-between border-b border-gray-300">
                <span className="font-bold text-black">Mardi</span>
                <p className="text-black text-sm sm:text-base">
                  10h - 12h30 , 14h - 19h
                </p>
              </li>
              <li className="pb-[3%] flex items-center justify-between border-b border-gray-300">
                <span className="font-bold text-black">Mercredi</span>
                <p className="text-black text-sm sm:text-base">
                  10h - 12h30 , 14h - 19h
                </p>
              </li>
              <li className="pb-[3%] flex items-center justify-between border-b border-gray-300">
                <span className="font-bold text-black">Jeudi</span>
                <p className="text-black text-sm sm:text-base">
                  10h - 12h30 , 14h - 19h
                </p>
              </li>
              <li className="pb-[3%] flex items-center justify-between border-b border-gray-300">
                <span className="font-bold text-black">Vendredi</span>
                <p className="text-black text-sm sm:text-base">
                  10h - 12h30 , 14h - 19h
                </p>
              </li>
              <li className="pb-[3%] flex items-center justify-between border-b border-gray-300">
                <span className="font-bold text-black">Samedi</span>
                <p className="text-black text-sm sm:text-base">
                  Je ne suis pas au salon.{" "}
                </p>
              </li>
              <li className="pb-[3%] flex items-center justify-between">
                <span className="font-bold text-black">Dimanche</span>
                <p className="text-black text-sm sm:text-base">
                  Je ne suis pas au salon.{" "}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section>
        <div class="bg-gray-100 py-12">
          <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="text-center">
              <h2 class="text-3xl font-bold leading-tight text-gray-900">
                Mon adresse
              </h2>
              <p class="mt-2 mb-4 text-lg text-gray-600">
                Vous pouvez me trouver au 49 rue des chênes, Violaine 62138.
              </p>
            </div>
            <div>
              <img src={map} alt="localisation_salon" />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-black text-white ">
        <div className=" flex flex-row ">
          <Prestation />
        </div>
      </section>

      <section className="bg-white text-black ">
        <div className="py-8 mx-auto max-w-[800px] text-center produits">
          <h3 id="produits" className="text-3xl font-bold dark:text-black mb-4">
            Mes Produits
          </h3>
          <p className="text-lg lg:text-left leading-relaxed">
            Je suis ravi de vous présenter une sélection de produits que
            j'utilise personnellement et que je recommande vivement. Chaque
            produit a été soigneusement choisi pour sa qualité, son efficacité
            et sa capacité à répondre aux besoins de mes clients. Que ce soit
            des produits de beauté, des accessoires de mode ou des outils de
            bien-être, vous trouverez ici une gamme diversifiée et de haute
            qualité pour vous aider à atteindre vos objectifs de style et de
            santé. Explorez cette collection avec confiance, sachant que chaque
            produit a été sélectionné avec soin pour vous offrir une expérience
            exceptionnelle.
          </p>
        </div>
        <Produit />
      </section>
    </main>
  );
}

export default Accueil;
