import React from "react";
import { createRoot } from "react-dom/client"; // Import de createRoot depuis react-dom
import { BrowserRouter } from "react-router-dom"; // Import de BrowserRouter depuis react-router-dom
import "./index.css";
import App from "./App";

// Utilisation de createRoot au lieu de ReactDOM.render
createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
