import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import Cookies from "js-cookie";

const MesRendezVous = ({}) => {
  const [rendezVous, setRendezVous] = useState([]);
  const [decodeToken, setDecodeToken] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour le chargement
  const token = Cookies.get("access-token");
  const email = decodeToken.email || "";

  const getUser = async (token) => {
    try {
      const response = await fetch("https://api-barber-one.vercel.app/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      if (response.ok) {
        setDecodeToken(data.data);
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  const validateToken = async (token) => {
    try {
      const response = await fetch(
        "https://api-barber-one.vercel.app/validate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ token }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        if (!data.valid) {
          // Gérer le cas où le token n'est pas valide
        } else {
          if (!decodeToken || Object.keys(decodeToken).length === 0) {
            getUser(token);
          }
        }
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  useEffect(() => {
    validateToken(token);
  }, [decodeToken, token]);

  useEffect(() => {
    const fetchRendezVous = async () => {
      try {
        const response = await fetch(
          "https://api-barber-one.vercel.app/appointments/email/" + email
        );
        if (response.ok) {
          let data = await response.json();
          data = data.sort((a, b) => {
            if (a.date === b.date) {
              return (
                new Date(a.date + "T" + a.heure) -
                new Date(b.date + "T" + b.heure)
              );
            }
            return new Date(a.date) - new Date(b.date);
          });
          const filteredData = data.filter((rdv, index, arr) => {
            const currentDate = rdv.date;
            const prevDate = index > 0 ? arr[index - 1].date : null;
            return currentDate !== prevDate;
          });
          setRendezVous(filteredData);
        } else {
          console.error(
            "Erreur lors de la récupération des rendez-vous:",
            response.status
          );
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des rendez-vous:", error);
      }
    };

    fetchRendezVous();
  }, [email]);

  const handleDelete = async (dateBefore) => {
    const dateFormat = dateBefore.substring(0, 10);
    const annee = dateFormat.substr(0, 4);
    const mois = dateFormat.substr(5, 2);
    const jour = dateFormat.substr(8, 10);
    // const jourInt = parseInt(jour) + 1;
    const date = annee + "-" + mois + "-" + jour;

    setIsLoading(true); // Définir isLoading à true lors de la suppression

    try {
      const response = await fetch(
        "https://api-barber-one.vercel.app/appointments/deleteRdv",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ token, date, email }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        document.location.reload();
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du rendez-vous:", error);
    } finally {
      setIsLoading(false); // Définir isLoading à false après la suppression
    }
  };

  return (
    <div className="max-w-lg mx-auto mt-10 mb-11 lg:px-6 pb-0">
      <h2 className="text-xl font-bold mb-4 lg:px-4">Mes rendez-vous</h2>
      {isLoading ? ( // Afficher l'indicateur de chargement si isLoading est vrai
        <p className="lg:px-4">Suppression en cours...</p>
      ) : rendezVous.length === 0 ? (
        <p className="lg:px-4">Aucun rendez-vous trouvé.</p>
      ) : (
        <div className="lg:overflow-x-auto">
          <table className="w-full lg:table-fixed">
            <thead>
              <tr className="border-b">
                <th className="py-2 lg:w-1/4">Date</th>
                <th className="py-2 lg:w-1/4">Heure</th>
                <th className="py-2 lg:w-1/4">Type</th>
                <th className="py-2 lg:w-1/4"></th>
              </tr>
            </thead>
            <tbody>
              {rendezVous.map((rdv, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 lg:w-1/4">
                    {format(new Date(rdv.date), "dd MMMM yyyy", { locale: fr })}
                  </td>
                  <td className="py-2 lg:w-1/4">{rdv.heure}</td>
                  <td className="py-2 lg:w-1/4">{rdv.type_prestation}</td>
                  <td className="py-2 lg:w-1/4">
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded"
                      onClick={() => handleDelete(rdv.date)}
                    >
                      Annuler
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="mt-4 text-center">
        <a href="/" className="text-blue-500 hover:text-blue-700 underline">
          Retour à l'accueil
        </a>
      </div>
    </div>
  );
};

export default MesRendezVous;
