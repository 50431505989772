import React from "react";
import moment from "moment";
import Cookies from "js-cookie";
import "moment/locale/fr"; // Importer les paramètres régionaux français

const SelectedAppointments = ({ selectedDate, selectedAppointments }) => {


  // Définir la langue de moment en français
  moment.locale("fr");
  const token = Cookies.get("access-token");

  // Formater la date sélectionnée en utilisant moment
  const formattedDate = moment(selectedDate).format("dddd D MMMM YYYY");

  const handleDelete = async (email) => {
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = selectedDate.getDate().toString().padStart(2, "0");
    const date = year + "-" + month + "-" + day;
    try {
      const response = await fetch(
        "https://api-barber-one.vercel.app/appointments/deleteRdv",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ token, date, email }),
        }
      );
      if (response.ok) {
        document.location.reload();
      }
    } catch (error) {
      console.error("Erreur lors de la supression du rendez-vous:", error);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white p-4 shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold mb-4">
        Rendez-vous pour le {formattedDate}
      </h2>
      {selectedAppointments.length > 0 ? (
        <ul>
          {selectedAppointments.map((appointment, index) => (
            <li key={index} className="border-b py-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="bg-blue-500 text-white rounded-full w-8 h-8 flex items-center justify-center mr-4">
                    {appointment.nom.charAt(0)}
                    {appointment.prenom.charAt(0)}
                  </div>
                  <div>
                    <p className="font-semibold">
                      {appointment.nom} {appointment.prenom}
                    </p>
                    <p className="text-sm font-semibold">
                      {appointment.number}
                    </p>
                    <p className="text-sm text-gray-500">
                      {appointment.type_prestation}
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <p className="text-sm font-semibold mr-4">
                    {appointment.heure}
                  </p>
                  <button
                    className="text-red-500 text-sm focus:outline-none"
                    onClick={() => handleDelete(appointment.email)}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500 text-sm">
          Aucun rendez-vous prévu pour cette date.
        </p>
      )}
    </div>
  );
};

export default SelectedAppointments;
