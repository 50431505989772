import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faInstagram } from "@fortawesome/free-brands-svg-icons";

import LogoBarber from "../assets/tmbarber.jpg";

function Footer() {
  return (
    <footer id="reseau" className="bg-black text-white py-8">
      <div className="container mx-auto flex flex-col  justify-between items-center px-4">
        {/* Middle Section */}
        <div className="mb-4 lg:mb-0 flex flex-col items-center lg:items-center">
          <img
            src={LogoBarber}
            alt="Logo"
            className="w-16 h-16 lg:w-24 lg:h-24 mb-2"
          />

          <p className="text-xs text-center lg:text-left">
            &copy; {new Date().getFullYear()} Martin Duhem. Tous droits
            réservés.
          </p>

          <h2 className="text-lg font-semibold lg:mr-[11px] mb-2">Suis-moi</h2>
          <ul className="flex">
            <SocialLink
              href="https://www.tiktok.com/@tm_barber9"
              icon={faTiktok}
            />
            <SocialLink
              href="https://www.instagram.com/tm_barber9/"
              icon={faInstagram}
            />
          </ul>
        </div>
      </div>
    </footer>
  );
}

function SocialLink({ href, icon }) {
  return (
    <li className="mr-4">
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:text-gray-300"
      >
        <FontAwesomeIcon icon={icon} size="2x" />
      </a>
    </li>
  );
}

export default Footer;
